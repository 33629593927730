import { Injectable } from '@angular/core';
import { LoaderOptionsObject } from '../interfaces/loaderOptions';
@Injectable({
  providedIn: 'root',
})
export class ConstantsService {
  public static USER_ROLES = {
    ADMIN: 'Admin',
    USER: 'User',
    MANAGER: 'Manager'
  };

  public static ADMIN = 'Admin';
  public static USER = 'User';
  public static MANAGER = 'Manager';
  public static SKELETON_LOADER_DEFAULT_CONFIG: LoaderOptionsObject = {
    rows: 5,
    cols: 4,
    colSpans: {
      0: 1,
    },
  };
  public static languages: any = [
    'en',
    'it',
    'fr',
    'de',
    'sp',
    'pr'
  ];

  public static createLoaderConfig(
    rows: number,
    cols: number,
    colSpans: number
  ) {
    const loaderObject: LoaderOptionsObject = {
      rows: rows,
      cols: cols,
      colSpans: {
        0: colSpans,
      },
    };
    return loaderObject;
  }

  public EDITOR_CONFIG = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{ font: [] }],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ['clean'],
      ['link'],
    ],
  };
  public LANGUAGES = {
    en: 'English',
    no: 'Norwegian',
  };

  public static descriptionList: any = [
    'Carryover Credit',
    'Payment to Account',
    'Prorated for Month',
    'Removed from Class & Credited',
    'Transfer',
  ];

  public MONTHS = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  };

  public DAYS: any = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
  };
  public PAYMENT_METHOD = ['Cash', 'Check', 'Card'];
  public DATE_TIME_FORMAT = {
    PRINT_DATE: 'MMMM Do YYYY, h:mm A',
    // SYN_DATE: 'MM/DD/YYYY',
    SYN_DATE: 'DD/MM/YYYY',
    CHAR_DATE: 'MMM DD, YYYY',
    INVOICE_MONTH: 'MMM YYYY',
    INVOICE_DATE: 'MMM DD, YYYY HH:mm',
    SHORT_DATE: 'DD-MM-YYYY',
    SHORT_DATE_INPUT: 'YYYY-MM-DD',
    DATE: 'dddd, MMMM DD, YYYY',
    TIME: 'HH:mm',
    DATE_TIME: 'dddd, MMMM DD, YYYY hh:mm A',
    AM_PM: 'HH:mm A',
    DOC_DATE: 'DD MMM, YYYY',
    DATE_AMPM: 'DD MMM, YYYY, h a',
  };

  public MONTHSDATA = [
    {
      name: 'January',
      short: 'Jan',
      number: 1,
      days: 31,
    },
    {
      name: 'February',
      short: 'Feb',
      number: 2,
      days: 28,
    },
    {
      name: 'March',
      short: 'Mar',
      number: 3,
      days: 31,
    },
    {
      name: 'April',
      short: 'Apr',
      number: 4,
      days: 30,
    },
    {
      name: 'May',
      short: 'May',
      number: 5,
      days: 31,
    },
    {
      name: 'June',
      short: 'Jun',
      number: 6,
      days: 30,
    },
    {
      name: 'July',
      short: 'Jul',
      number: 7,
      days: 31,
    },
    {
      name: 'August',
      short: 'Aug',
      number: 8,
      days: 31,
    },
    {
      name: 'September',
      short: 'Sep',
      number: 9,
      days: 30,
    },
    {
      name: 'October',
      short: 'Oct',
      number: 10,
      days: 31,
    },
    {
      name: 'November',
      short: 'Nov',
      number: 11,
      days: 30,
    },
    {
      name: 'December',
      short: 'Dec',
      number: 12,
      days: 31,
    },
  ];

  public SUPER_SEEDER: any = [
    'studentFirstName',
    'studentLastName',
    'studentPhoneNumber',
    'studentBalance',
    'studentEmail',
    'onlinePaymentLink',
    'parentPhoneNumber',
    // 'contactFirstName',
    // 'contactLastName',
    // 'contactEmail',
    // 'contactPhoneNumber',
    // 'contactRelation',
    // 'lastFiveTransactions',
    // 'lastTenTransactions',
    // 'lastFifteenTransactions',
  ];

  public SMSSUPER_SEEDER: any = [
    'studentFirstName',
    'studentMiddleName',
    'studentLastName',
    'studentPhoneNumber',
    'studentBalance',
    'studentEmail',
    'contactFirstName',
    'contactLastName',
    'contactEmail',
    'contactPhoneNumber',
    'contactRelation',
    'onlinePaymentLink',
  ];

  public Country_Code: any = [
    "AD", "AE", "AF", "AG", "AI", "AL", "AM",
    "AO", "AQ", "AR", "AS", "AT", "AU", "AW", "AX",
    "AZ", "BA", "BB", "BD", "BE", "BF", "BG", "BH",
    "BI", "BJ", "BL", "BM", "BN", "BO", "BQ", "BR",
    " BS", "BT", "BV", "BW", "BY", "BZ", "CA", "CC",
    "CD", "CF", "CG", "CH", "CI", "CK", "CL", "CM", "CN",
    "CO", "CR", "CU", "CV", "CW", "CX", "CY", "CZ", "DE",
    "DJ", "DK", "DM", "DO", "DZ", "EC", "EE", "EG", "EH", "ER",
    "ES", "ET", "FI", "FJ", "FK", "FM", "FO", "FR", "GA", "GB",
    "GD", "GE", "GF", "GG", "GH", "GI", "GL", "GM", "GN",
    "GP", "GQ", "GR", "GS", "GT",
    "GU", "GW", "GY", "HK", "HM", "HN", "HR", "HT", "HU",
    "ID", "IE", "IL", "IM", "IN",
    "IO", "IQ", "IR", "IS", "IT", "JE", "JM", "JO", "JP", "KE",
    "KG", "KH", "KI", "KM",
    "KN", "KP", "KR", "KW", "KY", "KZ", "LA", "LB",
    "LC", "LI", "LK", "LR", "LS", "LT",
    "LU", "LV", "LY", "MA", "MC", "MD", "ME", "MF", "MG", "MH",
    "MK", "ML", "MM", "MN",
    "MO", "MP", "MQ", "MR", "MS", "MT", "MU", "MV", "MW", "MX",
    "MY", "MZ", "NA", "NC",
    "NE", "NF", "NG", "NI", "NL", "NO", "NP", "NR", "NU",
    "NZ", "OM", "PA", "PE", "PF",
    "PG", "PH", "PK", "PL", "PM", "PN", "PR", "PS", "PT",
    "PW", "PY", "QA", "RE", "RO",
    "RS", "RU", "RW", "SA", "SB", "SC", "SD", "SE", "SG",
    "SH", "SI", "SJ", "SK", "SL",
    "SM", "SN", "SO", "SR", "SS", "ST", "SV", "SX", "SY",
    "SZ", "TC", "TD", "TF", "TG",
    "TH", "TJ", "TK", "TL", "TM", "TN", "TO", "TR", "TT",
    "TV", "TW", "TZ", "UA", "UG",
    "UM", "US", "UY", "UZ", "VA", "VC", "VE", "VG", "VI",
    "VN", "VU", "WF", "WS", "YE",
    "YT", "ZA", "ZM", "ZW"
  ];
}
export { };
